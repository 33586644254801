// Convert links to perform POST requests instead of GET
document.addEventListener('DOMContentLoaded', function () {
    const postLinks = document.querySelectorAll('a[data-method="POST"]');
    Array.from(postLinks).map(link => {
        const form = document.createElement('form');
        form.method = "POST";
        form.action = link.href;
        const csrfInput = document.createElement('input');
        csrfInput.type = 'hidden';
        csrfInput.name = 'csrfmiddlewaretoken';
        csrfInput.value = link.dataset.csrfToken;
        form.append(csrfInput);
        let callback = form.submit;
        if (link.dataset.confirm !== undefined) {
            callback = function () {
                if (confirm(link.dataset.confirm)) {
                    form.submit();
                }
                return false;
            }
        }
        document.body.append(form);
        link.onclick = callback;
    });
});

export function getImageDataUrlFromImageUrl(imageUrl) {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('CANVAS');
        const context = canvas.getContext('2d');
        const imageObj = new Image();
        const maxWidth = 300
        const maxHeight = 300

        imageObj.onerror = () => {
            console.error("Image URL not found:", imageUrl);
            resolve(null);
        };

        imageObj.onload = function () {
            // Calculate the aspect ratio
            const aspectRatio = this.width / this.height;

            // Determine whether to resize based on width or height
            if (this.width > maxWidth || this.height > maxHeight) {
                let newWidth, newHeight;

                if (aspectRatio > maxWidth / maxHeight) {
                    // Resize based on width
                    newWidth = maxWidth;
                    newHeight = Math.round(newWidth / aspectRatio);
                } else {
                    // Resize based on height
                    newHeight = maxHeight;
                    newWidth = Math.round(newHeight * aspectRatio);
                }

                // Set canvas size
                canvas.width = newWidth;
                canvas.height = newHeight;

                // Draw the resized image
                context.drawImage(this, 0, 0, newWidth, newHeight);

                // We can also apply lossless compression algorithms to make the size even more small
                const pngUrl = canvas.toDataURL('image/png');

                resolve(pngUrl);
            } else {
                // If the image doesn't need resizing, draw it directly
                canvas.width = this.width;
                canvas.height = this.height;
                context.drawImage(this, 0, 0);
                const pngUrl = canvas.toDataURL('image/png');
                resolve(pngUrl);
            }
        }
        imageObj.crossOrigin = 'anonymous';
        // todo: not an ideal solution, but this can bypass chrome cache so it won't trigger cors error
        // https://stackoverflow.com/questions/12648809/cors-policy-on-cached-image
        const randomChar = String.fromCharCode(65 + Math.floor(Math.random() * 26));
        imageObj.src = `${imageUrl}?${randomChar}=1`;
        // imageObj.src = imageUrl;
    });
}
