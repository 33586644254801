import Vue from 'vue'

export default {
  install(Vue) {
    const script = document.createElement('script')
    script.src = 'https://cdn.designhuddle.com/jssdk/v1/lib.min.js'
    script.async = true
    document.head.appendChild(script)

    // Adds the Design Huddle library to Vue's global scope when the window loads
    window.addEventListener('load', () => {
      Vue.prototype.$designHuddle = window.DSHDLib
    })
  }
}
